import { Button } from '@ver-uds/react';
import styled from 'styled-components';

const UnstyledButton = styled(Button)`
  && {
    justify-content: normal;

    font-weight: normal;
    text-decoration: underline;
  }
`;

export default UnstyledButton;
